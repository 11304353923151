<template>
	<div>
		<div style="padding: 56.25% 0 0 0; position: relative">
			<div class="w-full h-full bg-black absolute top-0 left-0 z-50"></div>
      <iframe :src="video" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; z-index: 51;" title="Paso a paso TiendaNube + MailUp"></iframe>
    </div>
	</div>
</template>

<script>
export default {
	name: "VideoGuide",
	props: {
		video: {
			type: String,
			required: false
		}
	}
}
</script>
